import { NgModule } from '@angular/core';
import { RouterModule, Routes, TitleStrategy } from '@angular/router';
import { PostfixPageTitleStrategy } from '@core/strategies/postfix-page-title-strategy.service';
import { PageNotFoundComponent } from '@modules/public/pages/page-not-found/page-not-found.component';

/**
 * NB! Route ordering is crucial.
 * Do not change!
 */
const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/public/public.module').then((m) => m.PublicModule),
  },
  {
    path: '',
    loadChildren: () => import('././modules/authenticated/authenticated.module').then((m) => m.AuthenticatedModule),
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      scrollOffset: [0, 50],
      scrollPositionRestoration: 'enabled',
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: TitleStrategy,
      useClass: PostfixPageTitleStrategy,
    },
  ],
})
export class AppRoutingModule {}
