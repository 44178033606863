import { FfNgxControlErrorMessages } from '@fagforbundet/ngx-components';
import { FF_NGX_USER_FORM_ERROR_MESSAGES } from '@fagforbundet/ngx-components';
import {
  BANK_ACCOUNT_NUMBER_FORM_ERROR_MESSAGES
} from '@shared/components/ff-ngx-bank-account-number-form/ff-ngx-bank-account-number-form.component';

export const controlErrorMessages = {
  ...FF_NGX_USER_FORM_ERROR_MESSAGES,
  ...BANK_ACCOUNT_NUMBER_FORM_ERROR_MESSAGES,
  unknown: ({ where }) => `Det skjedde en uventet feil ${where}`,
} satisfies FfNgxControlErrorMessages;
